.Navbar {
  width: 100%;
  position: fixed;
  height: 60px;
  background-color: #f2fde6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  border: 3px solid #275207;
}

.LeftSide {
  margin-left: 100px;
}

.LeftSide button {
  border: none;
  padding: 8px;
  font-size: 12px;
  color: rgb(234, 255, 231);
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  margin-left: 500px;
}
@media (max-width:600px) {
  .LeftSide button{
    margin-left: 0px;
  }
  
  
}

.RightSide {
  margin-right: 10px !important;
}

.RightSide button {
  position: relative !important;
  padding: 10px !important;
  border: none !important;
  border-radius: 5px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #275207 !important;
  margin-right: 10px !important;
  cursor: pointer !important;
}

.RightSide button div {
  position: absolute !important;
  background-color: red !important;
  top: -5px !important;
  right: -8px !important;
  height: 16px !important;
  width: 16px !important;
  font-size: 11px !important;
  border-radius: 999px !important;
}

.ExitCatalog {
  border: 2px solid green !important;
  color: #800020 !important;
  border-radius: 5px;
  font-size: 15px;
}

/* //navbar menu css */
/* 
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
} */

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: #f2fde6;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align: center;
  opacity: 0.95;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.MobileNavbar {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.floatChild {
  flex: 1;
  padding: 20px;
}
.floatChildSecond span {
  margin-left: 30%;
  margin-top: -5%;
}

@media (max-width: 992px) {
  .RightSide {
    margin: 0;
  }
}
@media (max-width: 300px) {
  .RightSide button {
    margin: 10px;
  }
}
