@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
html {
  scroll-behavior: smooth;
}

.App {
  background: url("./styles/assets/Bg5.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

* {
  font-family: "Open Sans", sans-serif;
}
