.WatchList {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.TopDiv {
  text-align: center;
  margin-top: 80px;
}

.TopDiv h2 {
  color: #648467;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: bold;
}

.MainContainer {
  /* border: 2px solid red; */
  width: 60%;
  text-align: center;
  background: #f2fde6;
  box-shadow: 5px 5px 5px #2c581d;
  border-radius: 20px;
}

.MainContainer table {
  /* border: 2px solid; */
  width: 80%;
  margin: auto;
  margin-bottom: 40px;
}

.MainContainer thead {
  /* height: 100px; */
  /* text-align: ; */
  vertical-align: text-top;
}

.MainContainer th {
  padding: 25px 5px;
  padding-bottom: 0px;
  border: 2px solid;
}

.MainContainer th div {
  /* border-bottom: 2px solid; */
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainContainer th span {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid; */
  /* height: 50%; */
  text-align: center;
}

.MainContainer th div td {
  padding-right: 10px;
}

.MainContainer tbody td {
  border: 2px solid rgb(8, 134, 18);
  padding: 5px;
}

.MainContainer tbody tr td div {
  display: flex;
}

.MainContainer tbody tr td div img {
  width: 40px;
  cursor: pointer;
}

.MainContainer h3 {
  color: #0a0a0a;

  /* margin-top: 20px; */
}

.MainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 100px;
}

.MainHeader button {
  width: fit-content;
  padding: 5px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: #67a92c;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.MainHeader p {
  width: 60px;
}

.ToolDataContainer {
  background: rgb(232, 255, 227);
  /* padding: 20px; */
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  /* padding-bottom: 42px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  font-family: "Times New Roman", Times, serif;
  cursor: pointer;
  position: relative;
  width: 80%;
}

.ToolDataTag {
  position: absolute;
  font-size: 20px;
  height: 22px;
  padding: 5px 10px;
  margin-top: -32px;
  background-color: #67a92c;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
}

.ToolHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #ccc;
  padding: 10px;
  color: #404040;
  font-size: 12px;
  text-align: center;
  align-items: center;
}

.ToolHeading button {
  background: #67a92c !important;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  border-bottom: 4px solid #377204;
}

.ToolDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.ToolDetails h2 {
  color: #67a92c;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 10px;
}

.ToolDetails p {
  color: #6e6e6e;
  font-size: 15px;
}

@media (max-width: 992px) {
  .WatchListMobile .MainContainer {
    width: 90%;
  }
  .WatchListMobile .ToolDataContainer {
    width: 90%;
  }
  .WatchListMobile .ToolDataContainer h1 {
    width: 90%;
  }
  .WatchListMobile .ToolDetails p {
    font-size: 13px;
    text-align: left;
  }
}
@media (max-width: 300px) {
  .WatchListMobile .ToolDataContainer h1 {
    font-size: 15px;
  }
  .WatchListMobile .ToolDetails p {
    font-size: 13px;
    text-align: left;
  }
  .WatchListMobile .ToolDetails img {
    width: 50% !important;
  }
}
