.HomePage {
  max-width: 100%;
  margin: auto;
  /* height: 100vh;
    overflow-y: scroll; */
  padding-top: 0px;
  display: flex;
  justify-content: flex-start;
}

.PageFlowWrapper {
  width: 18%;
  margin: 0% 5%;
  position: fixed;
}

.PageContentWrapper {
  width: 55%;
  margin-left: 28%;
  margin-top: 0px;
}

.StickyHeading {
  position: fixed;
  color: white;
  top: 10px;
  background-color: #275207;
  z-index: 9999;
  padding: 10px;
  padding-left: 0px;
  padding-right: 30px;
  width: 320px;
  text-align: center;
  left: calc(45% - 150px);
}






.FilterWrapper {
  width: 15%;
  margin: 0% 5%;
  right: 0;
  position: relative;
}

.TopDiv {
  text-align: center;
  margin-top: 80px;
}

.TopDiv h2 {
  color: #648467;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.TopDiv p {
  font-size: 14px;
  color: #6e6e6e;
  margin-bottom: 0px;
  line-height: 24px;
  height: 80px;
}

.PageFlowContainer {
  background: #f2fde6;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: auto;
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  align-items: center;
}

/* .PageFlowContainer:hover {
  box-shadow: 0 4px 8px #b9b9b9;
} */

.PageFlowElement {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 15%;
  /* line-height: 100px; */
}

.PageFlowElement p {
  /* line-height: 13px !important; */
  font-size: 12px;
  height: 19px;
  font-weight: bold;
  width: 35%;
  /* text-align: center; */
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: -10px;
  margin-left: 3px;

}

.ElementIcon {
  /* background: grey; */
  font-size: 30px;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
}

.IconContainer {
  width: 35px;
  height: 35px;
  margin-top: 0px;
  margin-bottom: 15px;
  background-color: grey;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  z-index: 11;
}

.DictionaryContainer {
  background: #f2fde6;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
}

.DictionaryContainer img {
  /* width: 20%; */
  margin: auto;
}

.AlphaContainer {
  border-bottom: solid 2px #ccc;
  padding: 3.5% 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  scroll-margin-top: 100px;
}

.AlphaContainer b {
  width: 75px;
  padding: 5px 15px;
  color: #275207;
  font-size: 26px;
}

.AlphaContainer ul {
  width: calc(100% - 75px);
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.AlphaContainer ul li {
  line-height: 24px;
  width: 28%;
  padding: 5px 15px;
  /* float: left; */
  text-align: left;
  color: #275207;
  list-style: none;
}

.AlphaContainer ul li div {
  display: flex;
  flex-direction: column;
  position: relative;
  /* top: -100%; */
  /* margin-bottom: -50px; */
  cursor: pointer;
}

/* 
.AlphaContainer ul li div img {
  max-height: 30px;
  margin-top: 40px;
} */

/* .AlphaContainer ul li:before {
  content: "•";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
} */

.AlphaContainer ul li span {
  color: rgb(110, 110, 110);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.ProgressBar {
  position: absolute;
  width: 5px;
  /* border: 2px solid; */
  background-color: rgb(110, 110, 110);
  height: 75%;
  /* top: 40%; */
  /* left: 50%; */
  /* transform: translate(-50%, 900%); */
  margin-left: 7%;
  top: 10%;
  left: calc(50% - 2.5px);
  z-index: 10;
}

.Progress {
  position: relative;
  width: 5px !important;
  height: 20%;
  background-color: #275207;
  z-index: 11;
}

.SpecDictionaryContainer {
  background: #f2fde6;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  padding-bottom: 42px;
  display: flex;
  flex-direction: row;
}

.SpecContainer {
  /* border: 2px solid; */
  /* height: 150px; */
  width: 80%;
}

.ImageContainer {
  /* border: 2px solid; */
  height: 150px;
  width: 20%;
}

.ImageContainer img {
  width: 100%;
  height: 100%;
  filter: invert(10%);
}

.SpecHeading {
  /* border-bottom: solid 2px #ccc; */
  padding: 5px;
  color: #275207;
}

.SpecListContainer {
  /* border: 2px solid red; */
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.SpecListContainer ul li {
  line-height: 24px;
  width: 100%;
  padding: 5px 15px;
  /* float: left; */
  list-style: none;
  text-align: left;
  color: #275207;
}

.SpecListContainer ul li:before {
  content: "•";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.SpecListContainer ul li span {
  color: rgb(110, 110, 110);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.ToolListContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 25px;
}

.ToolListContainer li {
  width: 20%;
  padding: 5px;
  line-height: 24px;
  /* width: 100%; */
  padding: 5px 15px;
  /* float: left; */
  list-style: none;
  text-align: left;
  color: #275207;
}

.ToolListContainer li a :hover {
  color: brown;
}

.ToolListContainer li:before {
  content: "•";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.ToolListContainer li a {
  color: rgb(110, 110, 110);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.ToolDictionaryContainer {
  background: #f2fde6;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  padding-bottom: 42px;
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
}

.ToolDataContainer {
  background: #f2fde6;
  /* padding: 20px; */
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  /* padding-bottom: 42px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  font-family: "Times New Roman", Times, serif;
  cursor: pointer;
  position: relative;
}

.ToolDataTag {
  position: absolute;
  font-size: 20px;
  height: 22px;
  padding: 5px 10px;
  margin-top: -32px;
  background-color: #275207;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  scroll-margin-top: 100px;
}

.ToolHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #ccc;
  padding: 10px;
  color: #404040;
  font-size: 12px;
  text-align: center;
  align-items: center;
}

.ToolHeading button {
  background: #275207 !important;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  border-bottom: 4px solid #377204;
}

.ToolDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.ToolDetails p {
  margin-left: 10%;
}


.ToolDetailsHeading {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* border-bottom: solid 2px #ccc; */
  padding: 10px;
  color: #404040;
  font-size: 12px;
  text-align: left;
  /* align-items: center; */
}

.ToolDetailsHeading div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ToolDetailsHeading span {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  /* align-items: center; */
}

.ToolDetailsHeading span ul {
  margin-left: 20px;
}

.ToolDetailsHeading button {
  background: #275207 !important;
  color: #fff;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  border-bottom: 4px solid #306600;
  margin-right: 5px;
}

.ToolDetailsHeading button:disabled {
  background: #606b56 !important;
  cursor: not-allowed;
}

.ToolDetails h2 {
  color: #275207;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
}

.ToolDetails p {
  color: #6e6e6e;
  font-size: 15px;
}

.ToolDetails li {
  width: 20%;
  padding: 5px;
  line-height: 24px;
  /* width: 100%; */
  padding: 0px 15px;
  /* float: left; */
  list-style: none;
  text-align: left;
  color: #275207;
}

.ToolDetails li:before {
  content: "•";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.ToolDetails li span {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.ToolDetailsContainer {
  background: #f2fde6;
  /* padding: 20px; */
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  font-family: "Times New Roman", Times, serif;
  padding: 20px;
}

.ToolDetailsContainer .ToolDetails {
  /* border-top: 2px solid #ccc; */
  background-color: #f1f1f1;
  margin-top: 0;
}

.DownloadsInfoContainer {
  display: flex;
}

.DownloadsSection {
  display: flex;
  flex-direction: column;
  /* border: 2px solid; */
  padding: 10px;
}

.DownloadsSection h4 {
  padding: 10px;
  padding-top: 0px;
  color: #6e6e6e;
  font-family: "Times New Roman", Times, serif;
}

.DownloadsSection div img {
  padding: 10px;
}

.SearchModal {
  background: #f2fde6;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px #2c581d;
  margin: 2% auto;
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SearchModal p {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  color: #6e6e6e;
}

.SearchModal h2 {
  margin-top: 20px;
  color: #c70b0b;
  text-align: center;
}

.SearchContainer {
  justify-self: center;
  display: flex;
  justify-content: center;
  /* width: fit-content; */
  padding: 0;
  height: max-content;
}

.SearchContainer button,
input {
  border: none;
  margin: 0;
  height: 100%;
  padding: 5px;
  border: solid 2px #275207;
  /* border-radius: 5px; */
}

.SearchContainer button {
  border-radius: 0px 5px 5px 0px;
}

.SearchContainer input {
  border-radius: 5px 0px 0px 5px;
  outline: none;
}

.SearchContainer button {
  background-color: #275207;
  color: #fff;
}

.FilterListContainer {
  /* border: 2px solid red; */
  border-bottom: solid 2px #ccc;
  padding: 5px;
  /* margin-left: 10px; */
  margin-bottom: 10px;
}

.FilterListContainer div {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.FilterListContainer div input {
  width: 15px;
  height: 15px;
}

.FilterListContainer div label {
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.ToggleButtonArray button {
  padding: 5px;
  /* margin: 2px; */
  margin-right: 5px;
  border-radius: 5px 5px 0px 0px;
  border: none;
  background: #9db688;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
}

.article_font {
  font-weight: bold;
  font-size: 15px;
  list-style: none;
  margin-left: -15%;
}

.onListHover li span:hover {
  color: brown;
}

@media (max-width: 992px) {
  .SearchHomePage {
    display: flex;
    flex-direction: column;
    padding: 2%;
  }

  .SearchHomePage .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .SearchHomePage .PageContentWrapper {
    width: 100%;
    margin-left: 0%;
    margin-top: 0px;
  }
}

@media (max-width: 992px) {
  .ToolHolderSinglePage {
    max-width: 90%;
    margin: auto;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .ToolHolderSinglePage .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .ToolHolderSinglePage .PageContentWrapper {
    width: 100%;
    margin-left: 0%;
  }

  .ToolHolderSinglePage .ToolDetailsHeading {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    color: #404040;
    font-size: 12px;
    text-align: left;
  }

  .ToolHolderSinglePage .ToolDetailsHeading h1 {
    text-align: center;
  }

  .ToolHolderSinglePage .ToolDetailsHeading div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }

  .ToolHolderSinglePage .ToolDetailsHeading div span {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .ToolHolderSinglePage .ToolDetailsHeading div span button {
    width: 40%;
  }

  .ToolHolderSinglePage span.ToolHeadingMobileSpan {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
  }

  .ToolHolderSinglePage span.ToolHeadingMobileSpan .article_font {
    margin-left: 0%;
    text-align: center;
  }

  .ToolHolderSinglePage span.ToolHeadingMobileSpan img {
    width: 40% !important;
    margin: 5% 0% 5% 30%;
  }
}

@media (max-width: 576px) {
  .ToolHolderSinglePage span.ToolHeadingMobileSpan img {
    width: 60% !important;
    margin: 5% 0% 5% 25%;
  }

  .ToolHolderSinglePage .ToolDetailsHeading div span button {
    width: 90%;
    margin-top: 5px;
    /* margin: 10px; */
  }

  .ToolHolderSinglePage .ToolDetailsHeading div span {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .machineToolFirst {
    max-width: 90%;
    margin: auto;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .machineToolFirst .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .machineToolFirst .PageContentWrapper {
    width: 100%;
    margin-left: 0%;
  }

  .machineToolFirst .ToolListContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 15px;
  }

  .machineToolFirst .ToolListContainer li {
    width: 7% !important;
    padding: 5px;
    line-height: 32px;
    padding: 5px 15px;
  }

  .machineToolFirst .DictionaryContainer li {
    width: fit-content;
  }

  .machineToolFirst .onListHover {
    width: 100%;
  }

  .machineToolFirst .AlphaContainer ul {
    width: 100%;
    padding: 0 5px;
    display: flex;
  }
}

@media (max-width: 576px) {
  .machineToolFirst .DictionaryContainer li {
    width: 100%;
    display: block;
  }
}

@media (max-width: 992px) {
  .MachineToolSecond {
    max-width: 90%;
    margin: auto;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .MachineToolSecond .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .MachineToolSecond .PageContentWrapper {
    width: 100%;
    margin-left: 0%;
  }

  .MachineToolSecond .DictionaryContainer li {
    width: fit-content;
  }

  .MachineToolSecond .onListHover {
    width: 100%;
  }

  .MachineToolSecond .AlphaContainer ul {
    width: 100%;
    padding: 0 5px;
    display: flex;
  }
}

@media (max-width: 576px) {
  .MachineToolSecond .DictionaryContainer li {
    width: 100%;
    display: block;
  }
}

@media (max-width: 992px) {
  .MachineToolsThird {
    max-width: 90%;
    margin: auto;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .MachineToolsThird .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .MachineToolsThird .PageContentWrapper {
    width: 100%;
    margin: 0%;
  }

  .MachineToolsThird .onListHover {
    width: 100%;
  }

  .MachineToolsThird .FilterWrapper {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .MachineToolsThird .AlphaContainer ul {
    width: 100%;
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .MachineToolsThird .AlphaContainer ul li {
    width: 100%;
    margin-left: -10%;
  }

  .MachineToolsThird .AlphaContainer ul li div {
    margin: 0px !important;
    top: 0 !important;
    width: 100%;
  }

  .MachineToolsThird .AlphaContainer ul li div img {
    width: 40% !important;
  }

  .MachineToolsThird .AlphaContainer ul li div span {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .MachineToolsFourth {
    max-width: 90%;
    margin: auto;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .MachineToolsFourth .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .MachineToolsFourth .PageContentWrapper {
    width: 100%;
    margin-left: 0%;
  }

  .MachineToolsFourth .FilterWrapper {
    width: 90%;
  }

  .MachineToolsFourth .ToolListContainer li {
    width: 35%;
    margin-left: 25px;
  }
}

@media (max-width: 576px) {
  .MachineToolsFourth .ToolListContainer li {
    width: 100%;
    margin: 0px;
  }
}

@media (max-width: 576px) {
  .MachineToolsFourth .ToolDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
  }

  .MachineToolsFourth .ToolDetails img {
    width: 40% !important;
    justify-self: center;
    margin-left: 30%;
    margin-top: 10px;
  }
}

@media (max-width: 992px) {
  .MachineToolsFifth {
    max-width: 90%;
    margin: auto;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .MachineToolsFifth .PageFlowWrapper {
    width: 100%;
    margin: 0%;
    position: static;
  }

  .MachineToolsFifth .PageContentWrapper {
    width: 100%;
    margin-left: 0%;
  }
}

@media (max-width: 576px) {
  .MachineToolsFifth .ToolDetailsHeading div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .MachineToolsFifth .ToolDetailsHeading div h1 {
    width: 100%;
    text-align: center;
  }

  .MachineToolsFifth .ToolDetailsHeading div span {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 20px auto;
  }

  .MachineToolsFifth .ToolDetailsHeading span {
    flex-direction: column;
  }

  .MachineToolsFifth .ToolDetailsHeading span ul li {
    text-align: center;
  }

  .MachineToolsFifth .ToolDetailsHeading span img {
    text-align: center;
    width: 50% !important;
    margin-left: 25%;
    margin-top: 20px;
  }

  .MachineToolsFifth .ToolDetailsHeading div span button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 400px) {
  .MachineToolsFifth .ToolDetailsHeading span img {
    text-align: center;
    width: 50% !important;
    margin-left: 25%;
    margin-top: 10px;
  }
}

.cold {
  color: #c70b0b;
}

.MachineToolsFifth {
  position: relative;
}

.PopUpFormShow {
  display: block !important;
  border: 2px solid red !important;
}

.PopUpForm {
  position: absolute;
  background-color: #fff;
  width: 35%;
  top: 130px;
  left: 32.5%;
  border-radius: 5px;
  padding-top: 20px;
}

.popForm {
  width: 90% !important;
  margin: 0 auto;
}

.popForm span {
  color: red;
  font-size: 14px;
}

.popForm input {
  display: block;
  margin-top: 10px;
  width: 90%;
}

.PopUpFormField {
  margin-top: 10px;
}

.popForm label {}

.popUpButton {
  text-align: center;
  padding: 10px 0px;
}

.popUpButton button {
  padding: 5px;
  margin: 10px;
}

.pageFlowDescription {
  font-weight: bold;
  color: #275207;
  font-size: 13px !important;
}

.fontChange {
  position: absolute;

  left: 15px;
}

.cont {
  width: "100%";
}

.form_modal_bg {
  position: absolute;
  /* width: 99.60vw; */
  /* height: 99.4vh; */
  height: 100%;
  width: 100%;
  
  top: 0;
  right: 0;
  z-index: 999;
  margin: 0px !important;
  /* border: 2px solid rebeccapurple; */
  background-color: #00000090;
  display: flex;
  justify-content: center;
  align-items: center;
}


.form_modal {
  
  z-index: 9999;
  border: 2px solid rgb(36, 7, 7);
  border-radius: 5%;
  width: 50%;
  height: 60%;
  background-color: #fff;
  padding: 40px;
  padding-top: 30px;
  padding-bottom: 80px;
}

.modal_head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  
}

.modal_head h2 {
  font-size: 2rem;
}

.modal_head button {
  border: none;
  border-radius: 999px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 35px;
  cursor: pointer;
}