* {
  margin: 0;
  padding: 0;
}
*, ::after, ::before {
  box-sizing:content-box !important;
}

.App {
  background-color: #f4f4f4;
  font-family: "Lato", sans-serif;
}
