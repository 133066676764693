.HomePage {
  max-width: 60%;
  margin: auto;
  /* height: 100vh;
  overflow-y: scroll; */
  padding-top: 130px;
}

.TopDiv {
  text-align: center;
}

.TopDiv h2 {
  color: #198754;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: bold;
}

.TopDiv p {
  font-size: 16px;
  color: #6e6e6e;
  margin-bottom: 20px;
  line-height: 24px;
}

.CardParent {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
}

.CardContainer {
  /* border: 2px solid blue; */
  width: 25%;
  padding: 0px 10px;
  margin: 4% 0;
}

.CardContainer a {
  color: #404040;
  text-decoration: none;
}

.Card {
  /* height: 296px; */
  padding: 5px;
  background: #f2fde6;
  box-shadow: 5px 5px 5px #2c581d;
  border-radius: 9px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  cursor: pointer;
}
.Card img {
  /* margin: 20px auto; */
  text-align: center;
  width: 165px;
  border-radius: 15px;
  filter: hue-rotate(0deg);
}

.CardHead {
  height: 54px;
  padding-top: 10px;
  text-align: center;
  font-size: 16px;
}
.CardInfo {
  font-size: 14px;
  text-align: left;
  line-height: 17px;
}
@media (max-width: 992px) {
  .HomePage {
    max-width: 90%;
    margin: auto;
    padding-top: 50px;
  }
  .CardParent {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
  }

  .CardContainer {
    /* border: 2px solid blue; */
    width: 90%;
    padding: 0px 10px;
    margin: 4% 0;
  }
  .top_left_Image
  {
    display: none;
  }

}
.top_left_Image
{
  margin-left: 1%;
  margin-top: 10%;
  position: fixed;
  /* width: 150px !important; */
  /* background: #f2fde6; */
  /* border: 5px solid red; */
}
.top_left_Image img
{
  background: #f2fde6;
}